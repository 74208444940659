<template lang="pug">
div.result-notes
  span(@click="showSaveBtn")
    v-textarea(
      v-model="course.notes"
      :loading="loading"
      ref="notes"
      @blur="checkBeforeSave"
      placehodler='Notes to course'
      outlined
    )
    v-btn(
      color="success"
      fixed
      bottom
      right
      v-if="saveBtnShowing"

    ) save notes
</template>

<script>
import { CourseModelDetailed } from '@/app/admin/models/courseModel'
import CourseService from '@/app/admin/models/courseService'
import Api from "@/services/api/api-courses"
import errorsMixin from '@/mixins/errors.mixin'
import {mapGetters, mapActions} from "vuex"

export default {
  name: 'ResultsCourseNotes',
  mixins: [errorsMixin],

  data: () => ({
    loading: false,
    saveBtnShowing: false,
    oldNotes: null
  }),

  mounted() {
    this.loadData(this.$route.query.course)
  },

  computed: {
    course: {
      get() {
        return this.$store.state.results.course
      },

      set(notes) {
        this.$store.results.commit('updateCourseNotes', notes)
      }
    },
    ...mapGetters({
      course: 'results/course'
    })
    
  },

  methods: {
    ...mapActions({
      loadCourse: 'results/loadCourse',
    }),
    async loadData(ID) {
      try {
        this.loading = true
        let res = await this.loadCourse(ID)
        this.oldNotes = this.course.notes
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
      finally {
        this.loading = false
      }
    },

    checkBeforeSave() {
      this.hideSaveBtn()
      this.save(this.$route.query.course, this.course.notes)
    },

    async save(ID, val) {
      try {
        this.loading = true
        let res = await new CourseService(Api).updateNotes(ID, val)
        this.course = res
        this.$notify({type: 'success', text: 'Notes saved'})
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
      finally {
        this.loading = false
      }
    },

    showSaveBtn() {
      this.saveBtnShowing = true
    },

    hideSaveBtn() {
      this.saveBtnShowing = false
    }
  }
}
</script>

<style lang="scss" scoped>
.result-notes {
  margin-top: 10px;
  padding-left: 14px;
  padding-right: 14px;
}
</style>